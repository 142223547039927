import { Stats } from "./interfaces";
import * as React from "react";
import {
  HeartIcon,
  FireIcon,
  TrophyIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid";
import { Tooltip } from "react-daisyui";

const Icons = {
  health: HeartIcon,
  strength: FireIcon,
  karma: TrophyIcon,
  gold: CurrencyDollarIcon,
};

export function AudioBookStats({ currentStats }: { currentStats?: Stats }) {
  if (!currentStats) {
    return null;
  }

  return (
    <div className="flex flex-row gap-2">
      {Object.keys(currentStats).map((stat) => {
        const Icon = Icons[stat as keyof Stats];
        return (
          <div key={stat} className="flex flex-col justify-center items-center">
            <Tooltip message={stat}>
              <Icon className="w-6 h-6 flex-none" />
            </Tooltip>
            {currentStats[stat as keyof Stats]}
          </div>
        );
      })}
    </div>
  );
}
