import * as React from "react";
import { ReactElement, useEffect } from "react";
import { useBlocker } from "react-router-dom";
import { Button, Modal } from "react-daisyui";

export default function ConfirmOnExit(): ReactElement {
  let blocker = useBlocker(() => true);

  useEffect(() => {
    const handler = (e: BeforeUnloadEvent): string => {
      e.preventDefault();
      return "The task currently running will *NOT* be terminated. Are you sure you want to leave?";
    };
    window.addEventListener("beforeunload", handler);
    return () => window.removeEventListener("beforeunload", handler);
  })

  return (
      <Modal open={blocker.state === "blocked"}>
        <Modal.Header>Are you sure you want to leave?</Modal.Header>
        <Modal.Body>
          The task currently running will *NOT* be terminated. Are you sure you want to leave?
        </Modal.Body>
        <Modal.Actions>
          <Button color="primary" onClick={() => blocker.reset()}>
            Stay on page
          </Button>
          <Button color="error" onClick={() => blocker.proceed()}>
            Exit anyway
          </Button>
      </Modal.Actions>
    </Modal>
  );
}
