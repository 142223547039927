import * as React from "react";


export default function Pegi3Icon() {
  return (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 426.082 519.378" enableBackground="new 0 0 426.082 519.378"
         xmlSpace="preserve">
      <g>
        <defs>
          <rect id="SVGID_1_" y="-3.624" width="426.082" height="536.125" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <rect clipPath="url(#SVGID_2_)" fill="#A5C400" width="425.717" height="425.717" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M183.924,178.78h14.471c22.993,0,27.25-11.916,27.25-32.776
		c0-25.975-5.101-34.488-17.889-34.488c-10.642,0-16.6,8.935-16.6,29.795v11.926h-62.581c0-63.435,20.007-98.775,83.873-98.775
		c48.954,0,79.612,28.098,79.612,86.006c0,38.313-11.926,54.49-30.658,61.728c22.145,12.348,34.914,25.539,34.914,71.099
		c0,60.448-22.566,97.078-87.271,97.078c-75.365,0-85.153-48.968-84.731-113.687h65.567v28.108c0,17.025,6.812,24.264,16.599,24.264
		c12.774,0,20.86-8.944,20.86-37.47c0-31.492-6.797-42.564-30.648-42.564h-12.77V178.78z" />
        <path clipPath="url(#SVGID_2_)" d="M0,519.378h425.717V0H0 M417.205,417.194H8.522V8.508h408.682V417.194z" />
        <path clipPath="url(#SVGID_2_)" d="M381.254,17.031v3.159h-6.729v21.33h-3.96v-21.33h-6.739v-3.159H381.254z M408.691,41.52
		h-3.949V22.231h-0.125l-6.777,19.288h-3.24l-6.442-19.288h-0.115V41.52h-3.959V17.031h6.279l6.106,18.219h0.125l5.934-18.219h6.164
		V41.52z" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="27.039,487.455 34.263,487.455 40.901,455.167 41.045,455.167
		47.976,487.455 54.759,487.455 64.115,447.929 57.64,447.929 51.523,480.245 51.365,480.245 44.808,447.929 37.512,447.929
		30.797,480.245 30.653,480.245 24.312,447.929 17.822,447.929 	" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="74.838,487.455 82.062,487.455 88.7,455.167 88.854,455.167
		95.785,487.455 102.548,487.455 111.924,447.929 105.434,447.929 99.322,480.245 99.164,480.245 92.597,447.929 85.302,447.929
		78.596,480.245 78.447,480.245 72.105,447.929 65.621,447.929 	" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="122.627,487.455 129.855,487.455 136.489,455.167 136.638,455.167
		143.579,487.455 150.352,487.455 159.718,447.929 153.237,447.929 147.111,480.245 146.963,480.245 140.396,447.929
		133.105,447.929 126.39,480.245 126.241,480.245 119.9,447.929 113.4,447.929 	" />
        <rect x="163.212" y="479.651" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.773" height="7.804" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M185.323,467.706
		c0-9.012,1.107-15.492,7.972-15.492c5.752,0,7.223,6.06,7.223,14.744c0,10.182-1.471,16.221-7.223,16.221
		C186.661,483.179,185.323,475.366,185.323,467.706 M179.504,500.435h6.184v-17.467h0.158c1.4,3.385,4.722,5.522,8.254,5.522
		c10.248,0,12.908-12.539,12.908-21.532c0-11.715-3.533-20.055-12.908-20.055c-4.28,0-7.736,2.962-8.551,5.905H185.4v-4.88h-5.896
		V500.435z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M219.035,463.564v-1.476
		c0-2.34,0.738-10.315,7.224-10.315c5.464,0,6.864,5.225,6.864,9.653v2.138H219.035z M239.603,468.435v-2.502
		c0-8.484-1.112-19.029-13.191-19.029c-12.165,0-14.092,11.428-14.092,21.32c0,13.565,4.055,20.267,13.728,20.267
		c10.756,0,13.258-9.808,13.258-14.006h-6.183c0,2.942-0.825,9.146-6.711,9.146c-7.593,0-7.593-9.146-7.593-15.195H239.603z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M258.623,452.214
		c6.644,0,7.967,7.813,7.967,15.492c0,8.092-1.323,14.447-8.044,14.447c-6.173,0-7.142-8.043-7.142-14.447
		C251.404,458.273,252.871,452.214,258.623,452.214 M272.408,447.929h-5.886v5.033h-0.153c-0.806-3.105-4.275-6.059-8.552-6.059
		c-10.027,0-12.894,10.104-12.894,20.803c0,5.234,0.729,19.749,12.232,19.749c3.901,0,7.583-1.994,8.925-5.302h0.154v5.167
		c0,2.723,0.287,8.839-7.689,8.839c-3.24,0-6.039-1.399-6.326-5.004h-6.193c1.016,9.874,10.545,9.874,12.961,9.874
		c7.967,0,13.421-4.275,13.421-16.077V447.929z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M280.921,487.455h6.174v-39.517
		h-6.174V487.455z M280.921,441.899h6.174v-7.095h-6.174V441.899z" />
        <rect x="296.969" y="479.651" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.787" height="7.804" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M313.65,487.455h6.192v-39.517h-6.192
		V487.455z M313.65,441.899h6.192v-7.095h-6.192V441.899z" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M328.317,487.455h6.174v-27.274c0-5.752,4.525-7.967,7.842-7.967
		c5.589,0,5.819,4.947,5.819,7.967v27.274h6.193v-28.022c0-4.63,0-12.529-10.401-12.529c-3.825,0-7.957,2.07-9.577,5.694h-0.163
		v-4.669h-5.887V487.455z" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M364.305,487.455h6.212v-34.646h6.769v-4.88h-6.769v-4.63
		c0-2.732,1.323-3.758,3.979-3.758h2.866v-5.312h-4.496c-5.972,0-8.561,3.097-8.561,9.069v4.63h-5.742v4.88h5.742V487.455z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M379.644,467.706
		c0,11.121,2.818,20.784,14.227,20.784c11.36,0,14.227-9.663,14.227-20.784c0-11.149-2.8-20.803-14.227-20.803
		C382.539,446.903,379.644,456.557,379.644,467.706 M393.727,483.64c-6.337,0-7.584-7.401-7.584-15.934
		c0-8.57,1.323-15.933,7.584-15.933c6.634,0,7.898,7.362,7.898,15.933C401.625,476.238,400.293,483.64,393.727,483.64" />
      </g>
    </svg>

  );
}
