import ClosableDropdown from "../../ds/ClosableDropdown";
import * as React from "react";
import { RegistryCategory, RegistryFilters } from "../../registry";
import { useStateLS } from "../../utils";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { KeyboardEventHandler, useEffect, useState } from "react";

interface MageBookListFiltersProps {
  initialFilters: Partial<RegistryFilters>;
  categories: RegistryCategory[];
  onChange: (filters: RegistryFilters) => void;
}

export function MageBookListFilters({ initialFilters, categories, onChange }: MageBookListFiltersProps) {
  const [filterCategory, setFilterCategory] = useStateLS<number>("filterCategory", categories[0].id);
  const [filterQuery, setFilterQuery] = useState<string>(initialFilters.query ?? "")

  const handleCategoryChange = (id: string) => {
    setFilterCategory(parseInt(id));
  };

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      let value = (e.target as HTMLInputElement).value
      if (value.length === 0) {
        value = undefined;
      }
      setFilterQuery(value)
    }
  }

  useEffect(() =>{
    onChange({ categoryId: filterCategory, query: filterQuery})
  }, [filterCategory, filterQuery])

  return (
    <div className="flex gap-2">
      <ClosableDropdown
        label={categories.filter((cat) => cat.id === filterCategory)[0]?.name ?? "Select a category"}
        data={categories.map((cat) => ({ id: cat.id.toString(), name: cat.name }))}
        clickCallback={handleCategoryChange}
      />

      <div className="flex-auto input input-bordered flex items-center gap-2 focus">
        <MagnifyingGlassIcon className="w-4 h-4" />
        <input className="w-full" onKeyDown={handleSearchKeyDown} defaultValue={filterQuery} placeholder="Filter series by title, genre, tags, ..." />
      </div>
    </div>
  );
}
