import { Dropdown, Input, Join } from "react-daisyui";
import { FormEvent, useState } from "react";
import * as React from "react";

export interface ClosableDropdownProps {
  label: string;
  data: {id: string; name: string}[];
  clickCallback: (id: string) => void;
}

export default function ClosableDropdown({label, data, clickCallback}: ClosableDropdownProps) {
  const [open, setOpen] = useState(false)
  return (
      <Dropdown open={open} key={open}>
        <Dropdown.Toggle onClick={() => setOpen(!open)}>{(open ? '▲' : '▼') + ' ' + label}</Dropdown.Toggle>
        <Dropdown.Menu className="z-40" onClick={(ev: FormEvent<HTMLElement>) => {
          clickCallback((ev.target as HTMLElement).dataset["id"]);
          setOpen(false)
        }}>
          {data.map((item) => (
            <Dropdown.Item key={item.id} data-id={item.id}>{item.name}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
  );
}
