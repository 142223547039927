import * as React from "react";

export function PlayNookLogo(): React.ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 513 567"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            d="M310.448 144.835C310.448 186.602 290.958 223.792 260.547 247.872C258.256 249.685 255.101 249.923 252.602 248.436L192.524 213.298C188.745 211.098 187.882 205.985 190.768 202.685C210.705 179.646 222.756 149.621 222.756 116.801C222.756 104.434 221.03 92.4542 217.817 81.0981L291.017 76.1335C303.337 96.1404 310.448 119.655 310.448 144.864V144.835Z"
            fill="white"
          />
          <path
            d="M379.304 176.672L162.231 54.58L257.511 0.891427C259.684 -0.327416 262.302 -0.327416 264.445 0.891427L468.96 118.465C473.691 121.17 473.632 128.008 468.871 130.654L386.148 176.613C384.006 177.802 381.417 177.802 379.304 176.613V176.672Z"
            fill="white"
          />
          <path
            d="M134.261 176.465L325.594 68.3144L229.243 16.8555L48.4138 118.644C43.6825 121.319 43.6528 128.097 48.3543 130.832L127.298 176.435C129.44 177.683 132.089 177.683 134.261 176.465Z"
            fill="white"
          />
          <path
            d="M410.994 325.966C374.751 305.157 332.735 303.551 296.7 317.91C293.992 318.98 292.236 321.596 292.236 324.509L292.831 394.043C292.861 398.383 296.819 401.713 301.074 400.88C331.009 395.054 363.116 399.543 391.623 415.923C402.365 422.076 411.917 429.538 420.159 437.981L460.925 377.039C449.707 356.407 432.806 338.541 410.935 325.996L410.994 325.966Z"
            fill="white"
          />
          <path
            d="M417.659 250.458L415.546 499.31L509.576 443.511C511.719 442.233 512.998 439.944 512.998 437.476L512.79 201.734C512.79 196.294 506.838 192.934 502.167 195.729L421.081 244.512C418.998 245.761 417.718 248.02 417.688 250.458H417.659Z"
            fill="white"
          />
          <path
            d="M295.807 462.862L484.939 350.937L481.606 460.038L303.217 565.989C298.545 568.753 292.623 565.424 292.623 559.983L292.355 468.868C292.355 466.37 293.665 464.081 295.807 462.803V462.862Z"
            fill="white"
          />
          <path
            d="M-0.000976562 203.577V437.862C-0.000976562 440.33 1.3083 442.619 3.45076 443.897L208.681 565.276C213.352 568.041 219.274 564.681 219.274 559.241V324.956C219.274 322.488 217.965 320.199 215.822 318.921L10.5923 197.542C5.92054 194.777 -0.000976562 198.137 -0.000976562 203.577ZM70.0753 418.153V312.529C70.0753 309.319 74.0329 307.803 76.1754 310.181L154.732 397.165C156.518 399.127 155.536 402.308 152.947 402.932L74.39 421.571C72.188 422.106 70.0753 420.412 70.0753 418.153Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
