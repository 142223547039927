import * as React from "react";
import { BookAnalysis } from "../defs";
import { MageBookAnalysisEntry } from "./MageBookAnalysisEntry";
import { AnalysisFilters } from "./defs";
import { Fragment } from "react";

export function MageBookAnalysis({
  analysis,
  filters,
  bookUrl,
}: {
  analysis: BookAnalysis;
  filters: AnalysisFilters;
  bookUrl: string;
}) {
  const hiddenMessages = analysis.messages.filter(
    (message) => !filters[message.level],
  ).length;

  return (
    <ul
      role="list"
      className="divide-y divide-gray-700 flex flex-col *:py-5 first:*:pt-0 last:*:pb-0"
    >
      {analysis.messages.map((message, idx) =>
        filters[message.level] ? (
          <MageBookAnalysisEntry
            key={idx}
            message={message}
            bookUrl={bookUrl}
          />
        ) : (
          <Fragment key={idx}></Fragment>
        ),
      )}

      {hiddenMessages > 0 && (
        <li className="text-sm italic">
          {hiddenMessages} messages are hidden, use the filters at the top of
          the page to show them
        </li>
      )}
    </ul>
  );
}
