import { useParams } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import * as React from "react";
import { ConfirmationButton } from "../../../ds/ConfirmationButton";
import * as Cookies from "js-cookie";
import useSWR from "swr";
import { RegistryEpisode } from "../../../registry";
import { Button, Modal, Select } from "react-daisyui";

export interface MissingActorAction {
  type: "missing_actor";
  actor_id: string;
}

const allColors = [
  // { name: "green", color: "#2A4C5B80" },
  // { name: "red", color: "#5B2A3380" },
  // { name: "yellow", color: "#58402380" },
  // { name: "purple", color: "#38236380" },
  { name: "gray", color: "#1f2937" },
  { name: "zync", color: "#27272a" },
  { name: "red", color: "#450a0a" },
  { name: "orange", color: "#431407" },
  { name: "amber", color: "#451a03" },
  { name: "yellow", color: "#422006" },
  { name: "lime", color: "#1a2e05" },
  { name: "green", color: "#052e16" },
  { name: "emerald", color: "#022c22" },
  { name: "teal", color: "#042f2e" },
  { name: "cyan", color: "#083344" },
  { name: "sky", color: "#082f49" },
  { name: "blue", color: "#172554" },
  { name: "indigo", color: "#1e1b4b" },
  { name: "violet", color: "#2e1065" },
  { name: "purple", color: "#3b0764" },
  { name: "fuchsia", color: "#4a044e" },
  { name: "pink", color: "#500724" },
  { name: "rose", color: "#4c0519" },
];

export function MissingActorModal({
  openModal,
  closeModal,
  action,
}: {
  openModal: boolean;
  closeModal: (completed: boolean) => void;
  action: MissingActorAction;
}) {
  const { chapterId } = useParams();
  const {
    data: chapter,
    isLoading: chapterIsLoading,
    error: chapterError,
  } = useSWR<RegistryEpisode>(`chapter-${chapterId}`, () =>
    fetch(`/api/books/chapters/${chapterId}/`).then((r) => r.json()),
  );
  const {
    data: colorMapping,
    isLoading: colorMappingIsLoading,
    error: colorMappingError,
  } = useSWR<{ [key: string]: string }>(
    chapter ?? `chapter-color-mapping-${chapter.series}`,
    () =>
      fetch(`/api/books/books/${chapter.series}/color-mapping`).then((r) =>
        r.json(),
      ),
  );
  const [selectedColor, setSelectedColor] = useState("");
  const [error, setError] = useState("");

  const createActor = () => {
    const csrftoken = Cookies.get("csrftoken");
    fetch("/api/books/actors/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-csrftoken": csrftoken,
      },
      body: JSON.stringify({
        name: action.actor_id,
        color: selectedColor,
        series: chapter.series,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw Error(`${response.statusText}: ${await response.text()}`);
        }
      })
      .then(() => {
        setError("");
        closeModal(true);
      })
      .catch((err: Error) => setError(err.message));
  };

  if (chapterIsLoading || colorMappingIsLoading) {
    return <div>...</div>;
  }

  if (chapterError || colorMappingError) {
    return (
      <div color="text-red-500">
        {chapterError && <p>{chapterError.toString()}</p>}
        {colorMappingError && <p>{colorMappingError.toString()}</p>}
      </div>
    );
  }

  return (
    <Modal
      backdrop
      open={openModal}
      onClose={() => closeModal(false)}
      className="text-neutral-content"
    >
      <Modal.Header>Missing actor</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed">
            An actor was found in the episode that is not yet configured in the
            system. Please configure it here to create it.
          </p>

          <Select
            className="w-full"
            onChange={(ev: ChangeEvent<HTMLSelectElement>) =>
              setSelectedColor(ev.target.value)
            }
          >
            <option value="">Select a color</option>
            {allColors.map((color) => {
              const existingActorWithSameColor = colorMapping.hasOwnProperty(
                color.color,
              )
                ? colorMapping[color.color]
                : null;
              return (
                <option
                  key={color.name}
                  value={color.color}
                  disabled={existingActorWithSameColor != null}
                >
                  {color.name}
                  {existingActorWithSameColor &&
                    `-- already used by ${existingActorWithSameColor}`}
                </option>
              );
            })}
          </Select>

          {selectedColor && (
            <div
              className="rounded-2xl p-4 text-white"
              style={{ backgroundColor: selectedColor }}
            >
              This is an example box, to see how text will appear on this color.
            </div>
          )}

          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>
      </Modal.Body>
      <Modal.Actions>
        <ConfirmationButton
          onClick={createActor}
          color="primary"
          disabled={selectedColor === ""}
        >
          Create Actor
        </ConfirmationButton>
        <Button color="neutral" onClick={() => closeModal(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
