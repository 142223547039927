import * as React from "react";


export default function Pegi3Icon() {
  return (<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px"
               viewBox="0 0 426.082 519.378"
               enableBackground="new 0 0 426.082 519.378"
               xmlSpace="preserve">
      <g>
        <defs>
          <rect id="SVGID_1_" y="-3.624" width="426.082" height="536.125" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <rect clipPath="url(#SVGID_2_)" fill="#F5A200" width="425.727" height="425.717" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M149.868,365.254H83.447V134.505H40.029V95.339
		c29.8,0.422,49.386-11.931,60.027-40.877h49.812V365.254z" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M280.125,189c12.77-11.926,22.145-20.439,43.427-20.439
		c39.593,0,60.453,33.635,60.453,96.226c0,58.756-15.328,105.586-85.569,105.586c-49.391,0-74.507-25.126-83.446-72.81
		c-3.83-20.86-4.262-46.414-4.262-81.314c0.432-42.583,0.853-68.127,4.692-88.987c8.939-47.679,34.047-72.801,83.438-72.801
		c44.692,0,80.892,27.677,80.892,74.512v15.756h-62.16v-7.248c0-21.282-8.091-26.818-18.731-26.818
		c-12.77,1.706-18.311,13.627-18.732,30.648V189z M299.28,229.868c-13.191,0-19.154,13.206-19.154,41.299
		c0,28.524,5.963,41.294,19.154,41.294c14.054,0,20.017-12.77,20.017-41.294C319.296,243.073,313.333,229.868,299.28,229.868" />
        <path clipPath="url(#SVGID_2_)" d="M0,519.378h425.727V0H0 M417.195,417.194H8.513V8.508h408.682V417.194z" />
        <path clipPath="url(#SVGID_2_)" d="M381.245,17.031v3.159h-6.729v21.33h-3.95v-21.33h-6.739v-3.159H381.245z M408.691,41.52
		h-3.959V22.231h-0.115L397.83,41.52h-3.221l-6.462-19.288h-0.115V41.52h-3.949V17.031h6.279l6.106,18.219h0.115l5.943-18.219h6.164
		V41.52z" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="27.034,487.455 34.258,487.455 40.896,455.167 41.045,455.167
		47.976,487.455 54.759,487.455 64.11,447.929 57.635,447.929 51.519,480.245 51.36,480.245 44.803,447.929 37.508,447.929
		30.797,480.245 30.648,480.245 24.307,447.929 17.817,447.929 	" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="74.833,487.455 82.057,487.455 88.695,455.167 88.854,455.167
		95.785,487.455 102.548,487.455 111.919,447.929 105.434,447.929 99.317,480.245 99.159,480.245 92.592,447.929 85.297,447.929
		78.591,480.245 78.447,480.245 72.106,447.929 65.616,447.929 	" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="122.627,487.455 129.851,487.455 136.489,455.167 136.633,455.167
		143.574,487.455 150.347,487.455 159.713,447.929 153.232,447.929 147.106,480.245 146.963,480.245 140.396,447.929
		133.101,447.929 126.385,480.245 126.236,480.245 119.895,447.929 113.4,447.929 	" />
        <rect x="163.212" y="479.651" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.773" height="7.804" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M185.324,467.706
		c0-9.012,1.107-15.492,7.966-15.492c5.757,0,7.228,6.06,7.228,14.744c0,10.182-1.471,16.221-7.228,16.221
		C186.656,483.179,185.324,475.366,185.324,467.706 M179.5,500.435h6.184v-17.467h0.158c1.404,3.385,4.721,5.522,8.254,5.522
		c10.248,0,12.913-12.539,12.913-21.532c0-11.715-3.547-20.055-12.913-20.055c-4.275,0-7.731,2.962-8.546,5.905H185.4v-4.88H179.5
		V500.435z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M219.035,463.564v-1.476
		c0-2.34,0.733-10.315,7.224-10.315c5.455,0,6.854,5.225,6.854,9.653v2.138H219.035z M239.593,468.435v-2.502
		c0-8.484-1.103-19.029-13.191-19.029c-12.16,0-14.083,11.428-14.083,21.32c0,13.565,4.055,20.267,13.723,20.267
		c10.752,0,13.264-9.808,13.264-14.006h-6.193c0,2.942-0.824,9.146-6.711,9.146c-7.583,0-7.583-9.146-7.583-15.195H239.593z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M258.632,452.214
		c6.634,0,7.957,7.813,7.957,15.492c0,8.092-1.323,14.447-8.043,14.447c-6.174,0-7.143-8.043-7.143-14.447
		C251.404,458.273,252.88,452.214,258.632,452.214 M272.408,447.929h-5.896v5.033h-0.153c-0.805-3.105-4.266-6.059-8.551-6.059
		c-10.019,0-12.885,10.104-12.885,20.803c0,5.234,0.729,19.749,12.232,19.749c3.911,0,7.593-1.994,8.916-5.302h0.163v5.167
		c0,2.723,0.277,8.839-7.688,8.839c-3.231,0-6.05-1.399-6.337-5.004h-6.193c1.026,9.874,10.546,9.874,12.961,9.874
		c7.977,0,13.431-4.275,13.431-16.077V447.929z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M280.912,487.455h6.192v-39.517
		h-6.192V487.455z M280.912,441.899h6.192v-7.095h-6.192V441.899z" />
        <rect x="296.979" y="479.651" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.777" height="7.804" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M313.64,487.455h6.212v-39.517h-6.212
		V487.455z M313.64,441.899h6.212v-7.095h-6.212V441.899z" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M328.317,487.455h6.174v-27.274c0-5.752,4.516-7.967,7.833-7.967
		c5.598,0,5.828,4.947,5.828,7.967v27.274h6.193v-28.022c0-4.63,0-12.529-10.411-12.529c-3.815,0-7.957,2.07-9.567,5.694h-0.153
		v-4.669h-5.896V487.455z" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M364.305,487.455h6.212v-34.646h6.759v-4.88h-6.759v-4.63
		c0-2.732,1.313-3.758,3.979-3.758h2.867v-5.312h-4.497c-5.981,0-8.561,3.097-8.561,9.069v4.63h-5.742v4.88h5.742V487.455z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M379.634,467.706
		c0,11.121,2.818,20.784,14.236,20.784c11.36,0,14.217-9.663,14.217-20.784c0-11.149-2.79-20.803-14.217-20.803
		C382.53,446.903,379.634,456.557,379.634,467.706 M393.727,483.64c-6.347,0-7.583-7.401-7.583-15.934
		c0-8.57,1.322-15.933,7.583-15.933c6.634,0,7.899,7.362,7.899,15.933C401.626,476.238,400.284,483.64,393.727,483.64" />
      </g>
    </svg>
  );
}
