import * as React from "react";


export default function Pegi3Icon() {
  return (<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px"
               viewBox="0 0 426.086 519.378"
               enableBackground="new 0 0 426.086 519.378"
               xmlSpace="preserve">
    <g>
      <defs>
        <rect id="SVGID_1_" y="-3.624" width="426.086" height="536.125" />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" overflow="visible" />
      </clipPath>
      <rect clipPath="url(#SVGID_2_)" fill="#F5A200" width="425.722" height="425.717" />
      <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M149.873,365.254H83.461V134.505H40.034V95.339
		c29.805,0.422,49.386-11.931,60.027-40.877h49.812V365.254z" />
      <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M376.351,302.241v63.022H213.719V299.26
		c74.071-100.894,97.922-117.924,97.922-156.664c0-15.755-5.973-25.117-17.457-25.117c-15.751,0-19.164,12.338-19.164,26.396v18.296
		h-62.159v-20.429c0-48.11,23.41-87.276,81.323-87.276c55.343,0,85.569,34.905,85.569,90.685c0,66.411-56.196,109.838-94.514,157.09
		H376.351z" />
      <path clipPath="url(#SVGID_2_)" d="M0.01,519.378h425.712V0H0.01 M417.209,417.194H8.518V8.508h408.691V417.194z" />
      <path clipPath="url(#SVGID_2_)" d="M381.26,17.031v3.159h-6.73v21.33h-3.969v-21.33h-6.729v-3.159H381.26z M408.696,41.52h-3.959
		V22.231h-0.115l-6.778,19.288h-3.24l-6.441-19.288h-0.115V41.52h-3.969V17.031h6.288l6.107,18.219h0.114l5.935-18.219h6.174V41.52z
		" />
      <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="27.039,487.455 34.268,487.455 40.901,455.167 41.05,455.167
		47.981,487.455 54.764,487.455 64.12,447.929 57.64,447.929 51.523,480.245 51.365,480.245 44.808,447.929 37.518,447.929
		30.802,480.245 30.653,480.245 24.312,447.929 17.822,447.929 	" />
      <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="74.838,487.455 82.062,487.455 88.7,455.167 88.858,455.167
		95.79,487.455 102.553,487.455 111.929,447.929 105.438,447.929 99.322,480.245 99.164,480.245 92.597,447.929 85.302,447.929
		78.601,480.245 78.452,480.245 72.111,447.929 65.621,447.929 	" />
      <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="122.632,487.455 129.855,487.455 136.494,455.167 136.643,455.167
		143.579,487.455 150.356,487.455 159.718,447.929 153.242,447.929 147.116,480.245 146.968,480.245 140.401,447.929
		133.105,447.929 126.39,480.245 126.246,480.245 119.905,447.929 113.405,447.929 	" />
      <rect x="163.212" y="479.651" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.773" height="7.804" />
      <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M185.328,467.706
		c0-9.012,1.107-15.492,7.972-15.492c5.752,0,7.223,6.06,7.223,14.744c0,10.182-1.471,16.221-7.223,16.221
		C186.661,483.179,185.328,475.366,185.328,467.706 M179.504,500.435h6.184v-17.467h0.158c1.404,3.385,4.721,5.522,8.259,5.522
		c10.248,0,12.908-12.539,12.908-21.532c0-11.715-3.538-20.055-12.908-20.055c-4.28,0-7.736,2.962-8.551,5.905h-0.148v-4.88h-5.901
		V500.435z" />
      <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M219.04,463.564v-1.476
		c0-2.34,0.733-10.315,7.223-10.315c5.465,0,6.864,5.225,6.864,9.653v2.138H219.04z M239.607,468.435v-2.502
		c0-8.484-1.111-19.029-13.195-19.029c-12.166,0-14.088,11.428-14.088,21.32c0,13.565,4.055,20.267,13.724,20.267
		c10.761,0,13.263-9.808,13.263-14.006h-6.184c0,2.942-0.824,9.146-6.715,9.146c-7.588,0-7.588-9.146-7.588-15.195H239.607z" />
      <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M258.628,452.214
		c6.644,0,7.966,7.813,7.966,15.492c0,8.092-1.322,14.447-8.043,14.447c-6.174,0-7.142-8.043-7.142-14.447
		C251.409,458.273,252.876,452.214,258.628,452.214 M272.413,447.929h-5.886v5.033h-0.154c-0.805-3.105-4.275-6.059-8.551-6.059
		c-10.027,0-12.894,10.104-12.894,20.803c0,5.234,0.729,19.749,12.232,19.749c3.901,0,7.583-1.994,8.925-5.302h0.153v5.167
		c0,2.723,0.288,8.839-7.688,8.839c-3.24,0-6.039-1.399-6.327-5.004h-6.193c1.017,9.874,10.546,9.874,12.962,9.874
		c7.966,0,13.421-4.275,13.421-16.077V447.929z" />
      <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M280.926,487.455h6.184v-39.517
		h-6.184V487.455z M280.926,441.899h6.184v-7.095h-6.184V441.899z" />
      <rect x="296.974" y="479.651" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.787" height="7.804" />
      <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M313.654,487.455h6.203v-39.517
		h-6.203V487.455z M313.654,441.899h6.203v-7.095h-6.203V441.899z" />
      <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M328.322,487.455h6.174v-27.274c0-5.752,4.515-7.967,7.842-7.967
		c5.589,0,5.81,4.947,5.81,7.967v27.274h6.192v-28.022c0-4.63,0-12.529-10.392-12.529c-3.825,0-7.957,2.07-9.577,5.694h-0.163
		v-4.669h-5.886V487.455z" />
      <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M364.311,487.455h6.212v-34.646h6.768v-4.88h-6.768v-4.63
		c0-2.732,1.323-3.758,3.979-3.758h2.866v-5.312h-4.496c-5.973,0-8.561,3.097-8.561,9.069v4.63h-5.743v4.88h5.743V487.455z" />
      <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M379.64,467.706
		c0,11.121,2.818,20.784,14.235,20.784c11.36,0,14.227-9.663,14.227-20.784c0-11.149-2.799-20.803-14.227-20.803
		C382.534,446.903,379.64,456.557,379.64,467.706 M393.722,483.64c-6.337,0-7.573-7.401-7.573-15.934
		c0-8.57,1.323-15.933,7.573-15.933c6.644,0,7.909,7.362,7.909,15.933C401.631,476.238,400.298,483.64,393.722,483.64" />
    </g>
  </svg>);
}
