import * as React from "react";
import { MouseEventHandler } from "react";
import { Button } from "react-daisyui";

export function ConfirmationButton(props: React.ComponentProps<typeof Button>) {
  const [confirming, setConfirming] = React.useState(false);
  const onClick: MouseEventHandler<HTMLButtonElement> = (ev) => {
    if (confirming) {
      props.onClick(ev);
      setConfirming(false);
    } else {
      setConfirming(true);
    }
  };

  return (
    <Button
      {...props}
      onClick={onClick}
      color={confirming ? "warning" : props.color}
    >
      {confirming ? "Are you sure?" : props.children}
    </Button>
  );
}
