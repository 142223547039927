import * as React from "react";
import { useState } from "react";
import {
  SpeechToTextAction,
  SpeechToTextModal,
} from "./logActionsModals/SpeechToTextModal";
import {
  MissingAudioAction,
  MissingAudioModal,
} from "./logActionsModals/MissingAudioModal";
import { CheckIcon } from "@heroicons/react/24/outline";
import {
  MissingActorAction,
  MissingActorModal,
} from "./logActionsModals/MissingActorModal";
import {
  ConfigureSynthAction,
  ConfigureSynthModal,
} from "./logActionsModals/ConfigureSynthModal";
import { Button } from "react-daisyui";

type Action =
  | SpeechToTextAction
  | MissingAudioAction
  | MissingActorAction
  | ConfigureSynthAction;

export function LogAction({ action }: { action: Action }): React.ReactElement {
  const [openModal, setOpenModal] = useState(false);
  const [completed, setCompleted] = useState(false);

  const closeModalWithResult = (completed: boolean) => {
    setCompleted(completed);
    setOpenModal(false);
  };

  let modalComponent: React.ReactElement | null = null;

  if (action.type === "speech_to_text") {
    modalComponent = (
      <SpeechToTextModal
        openModal={openModal}
        closeModal={closeModalWithResult}
        action={action}
      />
    );
  } else if (action.type === "missing_audio") {
    modalComponent = (
      <MissingAudioModal
        openModal={openModal}
        closeModal={closeModalWithResult}
        action={action}
      />
    );
  } else if (action.type === "missing_actor") {
    modalComponent = (
      <MissingActorModal
        openModal={openModal}
        closeModal={closeModalWithResult}
        action={action}
      />
    );
  } else if (action.type === "configure_synth") {
    modalComponent = (
      <ConfigureSynthModal
        openModal={openModal}
        closeModal={closeModalWithResult}
        action={action}
      />
    );
  } else {
    return <span>Unknown action {JSON.stringify(action)}</span>;
  }

  return (
    <>
      <Button
        color={completed ? "success" : "neutral"}
        size="xs"
        onClick={() => setOpenModal(true)}
      >
        {completed && <CheckIcon className="h-3 w-3 inline mr-1" />}
        {completed ? "Done!" : "Inspect issue"}
      </Button>
      {openModal && modalComponent}
    </>
  );
}
