import * as React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { RegistryEpisode } from "../../../registry";
import { Button, Modal } from "react-daisyui";

export interface ConfigureSynthAction {
  type: "configure_synth";
  catalog: string;
  catalog_with_arg: string;
}

export function ConfigureSynthModal({
  openModal,
  closeModal,
  action,
}: {
  openModal: boolean;
  closeModal: (completed: boolean) => void;
  action: ConfigureSynthAction;
}) {
  const { chapterId } = useParams();
  const {
    data: chapter,
    isLoading: chapterIsLoading,
    error: chapterError,
  } = useSWR<RegistryEpisode>(`chapter-${chapterId}`, () =>
    fetch(`/api/books/chapters/${chapterId}/`).then((r) => r.json()),
  );
  const catalogSpecificName =
    action.catalog_with_arg === action.catalog ? "" : action.catalog_with_arg;
  const {
    data: synthObjs,
    isLoading: synthObjIsLoading,
    error: synthObjError,
  } = useSWR<{ id: number }[]>(
    chapter ??
      `chapter-synth-objs-${chapter.series}-${action.catalog}-${catalogSpecificName}`,
    () =>
      fetch(
        `/api/books/synths/?series=${chapter.series}&category=${action.catalog}&category_specific_name=${catalogSpecificName}`,
      ).then((r) => r.json()),
  );

  if (chapterIsLoading || synthObjIsLoading) {
    return <div>...</div>;
  }

  if (chapterError || synthObjError) {
    return (
      <div color="text-red-500">
        {chapterError && <p>{chapterError.toString()}</p>}
        {synthObjError && <p>{synthObjError.toString()}</p>}
      </div>
    );
  }

  const synthAdminUrl =
    synthObjs.length === 0
      ? `/admin/books/synth/add/?category=${action.catalog}&category_specific_name=${catalogSpecificName}&series=${chapter.series}`
      : `/admin/books/synth/${synthObjs[0].id}`;

  return (
    <Modal
      backdrop
      open={openModal}
      onClose={() => closeModal(false)}
      className="text-neutral-content"
    >
      <Modal.Header>Configure synth</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed">
            A file could not be found; if you want it to be synthesized using a
            text-to-speech AI engine, please configure it.
          </p>

          <p className="text-md leading-relaxed">
            <strong>Catalog:</strong>{" "}
            <span className="underline">{action.catalog}</span>
            <br />
            <strong>Catalog specification:</strong>{" "}
            <span className="underline">{catalogSpecificName}</span>
          </p>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <Button tag="a" color="primary" href={synthAdminUrl} target="_blank">
          Configure
        </Button>
        <Button color="neutral" onClick={() => closeModal(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
