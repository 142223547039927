import * as Cookies from "js-cookie";

export function makeBookUrl(chapterId: number, experimental: boolean) {
  return (
    `/api/books/chapters/${chapterId}/edit?` +
    new URLSearchParams({
      experimental: experimental ? "true" : "false",
    })
  );
}

export const bookFetcher = async (url: string) => {
  const formData = new FormData();
  const csrftoken = Cookies.get("csrftoken");

  const res = await fetch(url, {
    method: "POST",
    body: formData,
    headers: { "x-csrftoken": csrftoken },
  });
  if (!res.ok) {
    throw {
      message: "An error occurred while fetching the data.",
      info: (await res.json()).error,
      status: res.status,
    };
  }

  return res.json();
};
