import { UserIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useUser } from "../utils";
import {
  AdjustmentsHorizontalIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import { Button, Dropdown } from "react-daisyui";

export function UserDropdown(): React.ReactElement {
  const { data: user } = useUser();
  return (
    <Dropdown size="xs" vertical="bottom" end className="z-40">
      <Dropdown.Toggle button={false}>
        <Button
          shape="square"
          variant="outline"
          size="sm"
          color="neutral"
          startIcon={<UserIcon className={"w-5 h-5 flex-none"} />}
          endIcon={<ChevronDownIcon className="w-4 h-4" />}
          className="w-16 rounded-full"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-52">
        {user.isStaff && (
          <Dropdown.Item href="/admin">
            <AdjustmentsHorizontalIcon className="w-4 h-4 mr-2" /> Admin panel
          </Dropdown.Item>
        )}
        {user.isSuperuser && (
          <Dropdown.Item href="https://filebrowser.playnook.games">
            <FolderIcon className="w-4 h-4 mr-2" />
            File browser
          </Dropdown.Item>
        )}
        <Dropdown.Item href="/logout">
          <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-2" />
          Sign out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
