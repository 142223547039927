import * as React from "react";
import { RegistryFilters, RegistrySeries } from "../../registry";
import { useStateLS } from "../../utils";
import useSWR from "swr";
import { Accordion, Badge, Indicator } from "react-daisyui";
import { MageBook } from "./MageBook";
import classNames = require("classnames");
import PegiIcon from "../../ds/icons/PegiIcon";
import { BookmarkSquareIcon, BookOpenIcon, FolderIcon, TagIcon } from "@heroicons/react/24/outline";


interface MageBookListProps {
  useExperimental: boolean;
  filters: RegistryFilters;
}

function stableBadgeColor(id: number) {
    switch (id % 9) {
      case 0: return "neutral"
      case 1: return "primary"
      case 2: return "secondary"
      case 3: return "accent"
      case 4: return "ghost"
      case 5: return "info"
      case 6: return "success"
      case 7: return "warning"
      default: return "error"
    }
  };


export function MageBookList({useExperimental, filters}: MageBookListProps) {
  const [openSeries, setOpenSeries] = useStateLS<number | undefined>(
    "openSeries",
    undefined,
  );

  const params = new URLSearchParams()

  if (filters.categoryId) {
    params.set('category', filters.categoryId.toString())
  }

  if (filters.query) {
    params.set('q', filters.query)
  }

  const { data: books, isLoading, error } = useSWR<RegistrySeries[]>(["registry", params.toString()], () =>
    fetch(`/api/books/books/?${params.toString()}`).then((r) => r.json()),
  );

  // [sp] in the Accordion we're not using <Join> because of a bug in daisyUI:
  // https://github.com/saadeghi/daisyui/issues/2413
  // once fixed, consider using <Join> and removing `rounded-none` in the accordion
  return (
      <div>
        {!books && isLoading && <>Loading...</>}
        {!books && error && <>{error}</>}
        {books && (
          <>
            {books.length === 0 && <div>No series found.</div>}
            {books.map((regBook, i) => (
              // using `openSeries` as key forces a rerender also when we hit the BACK browser button
              <Accordion
                key={`${regBook.pk}-${openSeries}`}
                defaultChecked={openSeries === regBook.pk}
                onChange={() => setOpenSeries(regBook.pk)}
                onClick={(e: Event) => openSeries === regBook.pk ? setOpenSeries(null) : null}
                icon="arrow"
                className={classNames(
                  "rounded-none border-neutral-content/10 join-item",
                  {
                    "border rounded-t-lg": i === 0,
                    "border-b border-x": i > 0,
                    "rounded-b-lg": i === books.length - 1,
                  },
                )}
              >
                <Accordion.Title
                  className={classNames("text-xl font-thin bg-base-200", {
                    "rounded-t-lg": i === 0,
                    "rounded-b-lg":
                      i === books.length - 1 && openSeries !== regBook.pk,
                  })}
                >
                  <div className="flex sm:items-center gap-2 justify-between flex-col sm:flex-row">
                    <div className="flex flex-col gap-1">
                      <div>{regBook.title}</div>
                      {regBook.tags != null && regBook.tags.length > 0 && (
                        <div className="flex gap-1">
                          {regBook.tags?.map((el) => (
                            <Badge key={el.id} color={stableBadgeColor(el.id)} outline={true}>{el.name}</Badge>
                            ))}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-row-reverse sm:flex-row justify-end items-center gap-2 font-light text-sm">
                      {regBook.genre != null && (
                        <Badge color={stableBadgeColor(regBook.genre.id)} className="flex gap-1">
                          <FolderIcon className="w-5 h-5" />
                          {regBook.genre?.name}
                        </Badge>
                      )}
                      <Badge color="primary" className="flex gap-1">
                        <BookOpenIcon className="w-5 h-5" />
                        {regBook.episodeSet.length}
                      </Badge>
                      {regBook.ageRestriction != null && (
                        <PegiIcon pegi={regBook.ageRestriction} />
                      )}
                    </div>
                  </div>
                </Accordion.Title>
                <Accordion.Content
                  className={classNames("bg-base-100 rounded-none", {
                    "rounded-b-lg":
                      i === books.length - 1 && openSeries === regBook.pk,
                  })}
                >
                  <ul className="flex flex-col gap-4 sm:gap-1 mt-4">
                    {regBook.episodeSet.map((regEpisode) => (
                      <MageBook
                        key={regEpisode.pk}
                        chapter={regEpisode}
                        useExperimental={useExperimental}
                      />
                    ))}
                  </ul>
                </Accordion.Content>
              </Accordion>
            ))}
          </>
        )}
      </div>
  );
}
