import * as React from "react";
import { ChangeEvent, ChangeEventHandler, useState } from "react";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { Button, Input, Join, useTheme } from "react-daisyui";

export function InputWithSearch({
  choices,
  defaultChoice,
  initialChoice,
  onChange,
  label,
}: {
  choices: string[];
  defaultChoice: string;
  initialChoice: string;
  onChange: (value: string) => void;
  label: string;
}): React.ReactElement {
  const [value, setValue] = useState(initialChoice),
    [choicesVisible, setChoicesVisible] = useState(false),
    choicesFiltered = choices.filter((el) => el.indexOf(value) >= 0),
    updateValue = (value: string) => {
      setValue(value);
      onChange(value);
    },
    onFocus: React.FocusEventHandler<HTMLInputElement> = () => {
      setChoicesVisible(true);
    },
    onBlur: React.FocusEventHandler<HTMLInputElement> = () => {
      setTimeout(() => {
        setChoicesVisible(false);
      }, 100);
    };

  return (
    <div className="flex flex-row gap-2 relative">
      <Join>
        <div className="inline-flex items-center rounded-l-md border border-r-0 bordered px-3 text-sm bg-neutral border-neutral-content/10 text-base-content">
          {label}
        </div>
        <Input
          value={value}
          onChange={(ev: ChangeEvent<HTMLInputElement>) =>
            updateValue(ev.target.value)
          }
          onFocus={onFocus}
          onBlur={onBlur}
          className="rounded-l-none"
        />
      </Join>
      {value != defaultChoice && (
        <Button color="neutral" onClick={() => updateValue(defaultChoice)}>
          <ArrowUturnLeftIcon className="w-4 h-4" />
        </Button>
      )}
      {choicesVisible && choicesFiltered.length > 0 && (
        <ul className="w-full flex flex-col absolute top-12 bg-gray-700 rounded-md border border-gray-600 max-h-[20em] overflow-y-scroll overflow-x-clip">
          {choicesFiltered.map((el) => (
            <li
              key={el}
              className="cursor-pointer hover:bg-gray-500 p-2"
              onClick={() => updateValue(el)}
            >
              {el}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
