import { RegistryEpisode } from "../../registry";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../utils";
import * as React from "react";
import { useState } from "react";
import {
  CheckIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import { Button } from "react-daisyui";
import EpisodeToolbar from "./EpisodeToolbar";

interface MageBookProps {
  chapter: RegistryEpisode;
  useExperimental: boolean;
}

export function MageBook({
                           chapter,
                           useExperimental
                         }: MageBookProps) {
  const [copiedFeedback, setCopiedFeedback] = useState(false);
  const copyToClipboardIcon = copiedFeedback ? (
    <CheckIcon className="h-5 w-5 text-gray-400" />
  ) : (
    <ClipboardIcon className="h-5 w-5 text-gray-400" />
  );

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(chapter.uid);
    setCopiedFeedback(true);
    setTimeout(() => {
      setCopiedFeedback(false);
    }, 1000);
  };

  return (
    <li className="flex justify-between gap-1 flex-col sm:flex-row">
      <div className="flex min-w-0 gap-x-4 content-around">
        <span className="min-w-0 flex-auto text-gray-300 flex items-center gap-1">
          {chapter.uid && (
            <Button
              shape="circle"
              color="neutral"
              size="xs"
              onClick={copyToClipboard}
              startIcon={copyToClipboardIcon}
            />
          )}
          <span className="truncate">{chapter.label}</span>
        </span>
      </div>
      <EpisodeToolbar chapter={chapter} useExperimental={useExperimental} />
    </li>
  );
}
