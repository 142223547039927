import { Button, Join } from "react-daisyui";
import { makeBookUrl } from "../database";
import {
  CogIcon,
  DocumentCheckIcon,
  LanguageIcon,
  PencilIcon,
  PlayIcon,
  WrenchScrewdriverIcon
} from "@heroicons/react/24/outline";
import { EpisodePhase } from "../EpisodePhase";
import * as React from "react";
import { RegistryEpisode } from "../../registry";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../utils";
import classNames = require("classnames");

interface EpisodeToolbarProps {
  chapter: RegistryEpisode;
  useExperimental: boolean;
  compact?: true;
}

export default function EpisodeToolbar({chapter, useExperimental, compact}: EpisodeToolbarProps) {
  const navigate = useNavigate();
  const weblateUrl = chapter.weblateLink
      ? `https://weblate.playnook.games/projects/${chapter.weblateLink}`
      : "#",
    iconCommonStyle = "-mx-1 h-4 w-4",
    labelCommonStyle = classNames("hidden", {"md:block": !compact});

  const { data: user } = useUser();

  return (
    <div className="flex flex-row gap-2">
      <div className="shrink-0">
        <Join horizontal>
          <Button
            tag="a"
            size="sm"
            color="neutral"
            href={makeBookUrl(
              chapter.pk,
              user.canUseExperimentalFeatures && useExperimental
            )}
            target="_blank"
            rel="noopener"
            className="join-item font-light"
            startIcon={<PencilIcon className={iconCommonStyle} />}
          >
            <span className={labelCommonStyle}>Edit</span>
          </Button>

          <Button
            size="sm"
            color="neutral"
            className="join-item font-light"
            onClick={() => navigate(`/validate/${chapter.pk}`)}
            startIcon={<DocumentCheckIcon className={iconCommonStyle} />}
          >
            <span className={labelCommonStyle}>Validate</span>
          </Button>

          <Button
            tag="a"
            size="sm"
            color="neutral"
            href={weblateUrl ?? "#"}
            target={chapter.weblateLink ? "_blank" : ""}
            rel="noopener"
            className="join-item font-light"
            disabled={chapter.weblateLink == null}
            startIcon={<LanguageIcon className={iconCommonStyle} />}
          >
            <span className={labelCommonStyle}>Translate</span>
          </Button>

          {user.isSuperuser && (
            <Button
              size="sm"
              color="neutral"
              className="join-item font-light"
              onClick={() => navigate(`/tasks/${chapter.pk}`)}
              startIcon={
                <WrenchScrewdriverIcon className={iconCommonStyle} />
              }
            >
              <span className={labelCommonStyle}>Tasks</span>
            </Button>
          )}

          <Button
            size="sm"
            color="neutral"
            className="join-item font-light"
            onClick={() => navigate(`/play/${chapter.pk}`)}
            startIcon={<PlayIcon className={iconCommonStyle} />}
          >
            <span className={labelCommonStyle}>Play</span>
          </Button>

          {user.isStaff && (
            <Button
              tag="a"
              size="sm"
              color="neutral"
              className="join-item font-light"
              href={`/admin/books/episode/${chapter.pk}/change/`}
              rel="noopener"
              startIcon={<CogIcon className={iconCommonStyle} />}
            >
              <span className={labelCommonStyle}>Config</span>
            </Button>
          )}
        </Join>
      </div>
      <EpisodePhase phase={chapter.phase} />
    </div>
  )
}
