import * as React from "react";
import { MageBookList } from "./MageBookList";
import { nonEmpty, useStateLS, useUser } from "../../utils";
import useSWR from "swr";
import { RegistryCategory } from "../../registry";
import { MageBookListFilters } from "./MageBookListFilters";
import { useSearchParams } from "react-router-dom";

export function MageBookListUI() {
  const { data: user } = useUser();
  const [useExperimental, setUseExperimental] = useStateLS(
    "useExperimental",
    false,
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, error } = useSWR<RegistryCategory[]>("categories", () =>
    fetch("/api/books/categories/").then((r) => r.json()),
  );

  if (isLoading) {
    return <>Loading...</>
  }

  if (error) {
    return <>Error: {error}</>
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="w-full md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h3 className="font-bold text-lg leading-8 text-gray-100 sm:truncate sm:tracking-tight">
            Series Registry
          </h3>
        </div>
        {user.canUseExperimentalFeatures && (
          <div className="mt-4 flex md:ml-4 md:mt-0">
            <div className="flex h-6 items-center">
              <input
                type="checkbox"
                id="experimentalFeatures"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                checked={useExperimental}
                onChange={(ev) => {
                  setUseExperimental(ev.target.checked);
                }}
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label className="font-medium" htmlFor="experimentalFeatures">
                Use experimental features
              </label>
            </div>
          </div>
        )}
      </div>

      <MageBookListFilters initialFilters={{query: searchParams.get("query") ?? ""}} categories={data} onChange={(filters) => setSearchParams(nonEmpty({
        query: filters.query,
        categoryId: filters.categoryId.toString(),
      }))} />

      <MageBookList useExperimental={useExperimental} filters={{
        query: searchParams.get("query"),
        categoryId: searchParams.has("categoryId") ? parseInt(searchParams.get("categoryId")) : null,
      }} />
    </div>
  );
}
