import { App } from "./components/App";
import * as React from "react";
import { createRoot } from "react-dom/client";

import "./assets/tailwind.css";

const container = document.getElementById("app");

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
