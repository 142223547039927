import {
  ArchiveBoxIcon,
  LanguageIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import { PegiValue } from "./ds/icons/PegiIcon";

export type Phase = "wr" | "te" | "tr" | "rc" | "ft" | "ac" | "av";

export interface RegistryEpisode {
  pk: number;
  uid?: string;
  label: string;
  weblateLink: string | null;
  seriesTitle: string;
  series: number;
  phase?: Phase;
}

export interface RegistrySeries {
  pk: number;
  title: string;
  episodeSet: RegistryEpisode[];
  genre?: {
    id: number;
    name: string;
  };
  tags?: {
    id: number;
    name: string;
  }[];
  ageRestriction?: PegiValue;
}

export interface RegistryCategory {
  id: number;
  name: string;
}

export interface RegistryFilters {
  categoryId?: number;
  query?: string;
}

export type TaskName =
  | "export-script-for-actors"
  | "export-i18n-to-weblate"
  | "generate-package";

interface TaskConfig {
  name: TaskName;
  label: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  enabled: boolean;
}

export const availableTasks: TaskConfig[] = [
  {
    name: "export-i18n-to-weblate",
    label: "Push to Weblate",
    icon: LanguageIcon,
    enabled: true,
  },
  {
    name: "export-script-for-actors",
    label: "Export script for actors",
    icon: QueueListIcon,
    enabled: true,
  },
  {
    name: "generate-package",
    label: "Generate package for mobile app",
    icon: ArchiveBoxIcon,
    enabled: true,
  },
];
