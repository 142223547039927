import * as React from "react";


export default function Pegi3Icon() {
  return (<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 425.292 519.372" enableBackground="new 0 0 425.292 519.372"
	 xmlSpace="preserve">
<g>
	<defs>
		<rect id="SVGID_1_" y="-3.624" width="426.082" height="536.12"/>
	</defs>
	<clipPath id="SVGID_2_">
		<use xlinkHref="#SVGID_1_"  overflow="visible"/>
	</clipPath>
	<rect clipPath="url(#SVGID_2_)" fill="#E2011A" width="425.717" height="425.712"/>
	<path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M149.866,365.25H83.451V134.503H40.028V95.337
		c29.805,0.422,49.39-11.93,60.031-40.876h49.807V365.25z"/>
	<path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M211.162,276.699c0-30.657,6.812-52.361,31.923-71.524
		c-20.86-20.86-28.52-39.17-28.52-67.264c0-48.963,31.079-83.45,82.166-83.45c51.086,0,82.166,34.487,82.166,83.45
		c0,28.093-7.66,46.403-28.521,67.264c25.126,19.164,31.923,40.867,31.923,71.524c0,55.765-17.438,93.67-85.568,93.67
		C228.619,370.369,211.162,332.464,211.162,276.699 M296.731,233.273c-15.329,0-19.164,10.641-19.164,39.597
		c0,29.368,3.835,39.587,19.164,39.587c15.319,0,19.163-10.219,19.163-39.587C315.894,243.914,312.05,233.273,296.731,233.273
		 M296.731,110.662c-14.045,0-17.457,6.811-17.457,36.194c0,29.368,3.412,36.184,17.457,36.184c14.044,0,17.457-6.816,17.457-36.184
		C314.188,117.473,310.775,110.662,296.731,110.662"/>
	<path clipPath="url(#SVGID_2_)" d="M0,519.372h425.717V0H0 M417.205,417.189H8.522V8.508h408.682V417.189z"/>
	<path clipPath="url(#SVGID_2_)" d="M381.254,17.03v3.159h-6.739v21.33h-3.959v-21.33h-6.729V17.03H381.254z M408.691,41.519
		h-3.959V22.231h-0.115l-6.787,19.288h-3.23l-6.451-19.288h-0.115v19.288h-3.96V17.03h6.289l6.106,18.219h0.115l5.935-18.219h6.173
		V41.519z"/>
	<polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="27.039,487.449 34.262,487.449 40.901,455.162 41.049,455.162
		47.976,487.449 54.763,487.449 64.114,447.924 57.639,447.924 51.522,480.24 51.364,480.24 44.807,447.924 37.512,447.924
		30.796,480.24 30.653,480.24 24.312,447.924 17.821,447.924 	"/>
	<polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="74.837,487.449 82.061,487.449 88.694,455.162 88.853,455.162
		95.784,487.449 102.547,487.449 111.922,447.924 105.432,447.924 99.316,480.24 99.163,480.24 92.596,447.924 85.301,447.924
		78.595,480.24 78.446,480.24 72.105,447.924 65.62,447.924 	"/>
	<polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="122.625,487.449 129.854,487.449 136.488,455.162 136.636,455.162
		143.577,487.449 150.35,487.449 159.716,447.924 153.235,447.924 147.109,480.24 146.961,480.24 140.394,447.924 133.104,447.924
		126.388,480.24 126.24,480.24 119.898,447.924 113.398,447.924 	"/>
	<rect x="163.21" y="479.646" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.773" height="7.804"/>
	<path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M185.321,467.701
		c0-9.012,1.112-15.492,7.971-15.492c5.752,0,7.228,6.059,7.228,14.744c0,10.181-1.476,16.221-7.228,16.221
		C186.659,483.174,185.321,475.36,185.321,467.701 M179.502,500.429h6.183v-17.466h0.158c1.404,3.384,4.721,5.521,8.254,5.521
		c10.248,0,12.908-12.539,12.908-21.531c0-11.715-3.542-20.055-12.908-20.055c-4.28,0-7.736,2.962-8.551,5.905h-0.144v-4.88h-5.9
		V500.429z"/>
	<path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M219.032,463.56v-1.477
		c0-2.339,0.738-10.314,7.229-10.314c5.455,0,6.864,5.225,6.864,9.653v2.138H219.032z M239.605,468.43v-2.502
		c0-8.484-1.112-19.029-13.201-19.029c-12.16,0-14.087,11.427-14.087,21.32c0,13.564,4.055,20.266,13.723,20.266
		c10.766,0,13.259-9.807,13.259-14.006h-6.174c0,2.943-0.824,9.146-6.721,9.146c-7.587,0-7.587-9.146-7.587-15.194H239.605z"/>
	<path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M258.625,452.209
		c6.634,0,7.957,7.813,7.957,15.492c0,8.091-1.323,14.446-8.034,14.446c-6.174,0-7.151-8.043-7.151-14.446
		C251.396,458.268,252.873,452.209,258.625,452.209 M272.41,447.924h-5.886v5.033h-0.163c-0.806-3.106-4.267-6.059-8.552-6.059
		c-10.018,0-12.894,10.104-12.894,20.803c0,5.234,0.729,19.748,12.232,19.748c3.911,0,7.593-1.994,8.935-5.302h0.153v5.167
		c0,2.723,0.288,8.839-7.688,8.839c-3.24,0-6.049-1.399-6.327-5.004h-6.192c1.016,9.874,10.535,9.874,12.951,9.874
		c7.967,0,13.431-4.275,13.431-16.076V447.924z"/>
	<path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M280.913,487.449h6.184v-39.516
		h-6.184V487.449z M280.913,441.895h6.184V434.8h-6.184V441.895z"/>
	<rect x="296.97" y="479.646" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.787" height="7.804"/>
	<path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M313.641,487.449h6.203v-39.516
		h-6.203V487.449z M313.641,441.895h6.203V434.8h-6.203V441.895z"/>
	<path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M328.318,487.449h6.174v-27.273c0-5.752,4.516-7.967,7.832-7.967
		c5.599,0,5.819,4.946,5.819,7.967v27.273h6.193v-28.021c0-4.63,0-12.529-10.401-12.529c-3.815,0-7.947,2.07-9.567,5.694h-0.163
		v-4.669h-5.887V487.449z"/>
	<path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M364.306,487.449h6.212v-34.646h6.768v-4.88h-6.768v-4.63
		c0-2.732,1.313-3.758,3.979-3.758h2.866v-5.312h-4.496c-5.982,0-8.561,3.097-8.561,9.069v4.63h-5.742v4.88h5.742V487.449z"/>
	<path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M379.634,467.701
		c0,11.12,2.819,20.783,14.236,20.783c11.36,0,14.227-9.663,14.227-20.783c0-11.149-2.8-20.803-14.227-20.803
		C382.53,446.898,379.634,456.552,379.634,467.701 M393.717,483.634c-6.337,0-7.573-7.401-7.573-15.933
		c0-8.57,1.323-15.933,7.573-15.933c6.644,0,7.909,7.362,7.909,15.933C401.626,476.232,400.284,483.634,393.717,483.634"/>
</g>
</svg>
	)}
