import * as React from "react";

import { Book, NextChoice, NextContinue, NextRoll, Stats } from "./interfaces";
import { HistoryStep } from "./AudioBookStep";
import {
  ArrowRightIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/solid";
import * as classNames from "classnames";
import { Button } from "react-daisyui";

const conditionIsSatisfied = (
  condition: NextChoice["choices"][number]["condition"] | undefined,
  stats: Stats,
): boolean => {
  if (!condition) {
    return true;
  }
  return stats[condition.stat] >= condition.requiredAmount;
};

interface Props {
  step: HistoryStep;
  book: Book;
  moveForward: () => void;
  changeChapter: (chapterId: string, text: string | null) => void;
  roll: (next: NextRoll) => void;
  restartFromLastCheckpoint: () => void;
  currentStats: Stats;
}
export function AudioBookNext({
  step,
  book,
  moveForward,
  changeChapter,
  roll,
  restartFromLastCheckpoint,
  currentStats,
}: Props) {
  if (step.type !== "page") {
    return null;
  }

  const chapter = book.chapters[step.chapterId];
  const nextStep = step.position + 1;

  if (chapter.final === "success") {
    return (
      <p className="italic text-yellow-500 font-light">You win! The End.</p>
    );
  }

  if (chapter.final === "failure") {
    return (
      <>
        <p className="italic text-red-500 font-light mb-2">
          You lose. The End.
        </p>
        <Button color="neutral" onClick={restartFromLastCheckpoint}>
          Do you want to retry from the last checkpoint?
        </Button>
      </>
    );
  }

  if (nextStep < chapter.pages.length) {
    return (
      <button
        type="button"
        onClick={moveForward}
        className="rounded-full flex flex-row gap-2 text-gray-100 shadow-sm hover:bg-gray-700 p-2"
      >
        <ArrowRightIcon className="flex-none h-6 w-6 rounded-fullfont-bold" />
      </button>
    );
  }

  if (chapter.next.type === "continue") {
    return (
      <button
        type="button"
        onClick={() =>
          changeChapter((chapter.next as NextContinue).chapter, null)
        }
        className="rounded-full flex flex-row gap-2 text-gray-100 shadow-sm hover:bg-gray-700 p-2"
      >
        <ArrowRightIcon className="flex-none h-6 w-6 rounded-fullfont-bold" />
      </button>
    );
  }

  if (chapter.next.type === "roll") {
    return (
      <button
        type="button"
        onClick={() => roll(chapter.next as NextRoll)}
        className="rounded-full flex flex-row gap-2 text-gray-100 shadow-sm hover:bg-gray-700 p-2 pr-4"
      >
        <ViewfinderCircleIcon className="flex-none h-6 w-6 rounded-fullfont-bold" />
        Roll a dice
      </button>
    );
  }

  if (chapter.next.type === "choices") {
    return (
      <div className="flex flex-col gap-2">
        {chapter.next.choices.map((choice, idx) => (
          <div key={choice.chapter}>
            <button
              className={classNames(
                "rounded-md bg-white/10 px-3 py-2 font-semibold text-white shadow-sm hover:bg-white/20 flex flex-row justify-center",
                {
                  "opacity-50 cursor-not-allowed": !conditionIsSatisfied(
                    choice.condition,
                    currentStats,
                  ),
                },
              )}
              onClick={() => changeChapter(choice.chapter, choice.text)}
              disabled={!conditionIsSatisfied(choice.condition, currentStats)}
            >
              <span className="w-6 h-6 inline-flex items-center justify-center flex-none rounded-sm text-sm bg-gray-200 mr-2 text-gray-700">
                {idx + 1}
              </span>
              {choice.text}
              {choice.condition &&
                ` (${choice.condition.stat}: ${choice.condition.requiredAmount})`}
            </button>
          </div>
        ))}
      </div>
    );
  }
}
