import * as React from "react";


export default function Pegi3Icon() {
  return (
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 425.623 519.375" enableBackground="new 0 0 425.623 519.375"
         xmlSpace="preserve">
      <g>
        <defs>
          <rect id="SVGID_1_" y="-3.621" width="426.082" height="536.12" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlinkHref="#SVGID_1_" overflow="visible" />
        </clipPath>
        <rect y="0.003" clipPath="url(#SVGID_2_)" fill="#A5C400" width="425.717" height="425.712" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M126.872,59.573h167.75c-18.741,96.225-48.536,196.696-50.243,305.68h-68.975
		c4.261-83.44,22.571-166.038,42.578-246.929h-91.11V59.573z" />
        <path clipPath="url(#SVGID_2_)"
              d="M0.009,519.375h425.708V0.003H0.009 M417.204,417.192H8.522V8.511h408.681V417.192z" />
        <path clipPath="url(#SVGID_2_)" d="M381.254,17.033v3.159h-6.739v21.33h-3.949v-21.33h-6.729v-3.159H381.254z M408.691,41.522
		h-3.959V22.234h-0.115l-6.787,19.288h-3.221l-6.452-19.288h-0.124v19.288h-3.95V17.033h6.279l6.106,18.219h0.115l5.943-18.219
		h6.164V41.522z" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="27.043,487.452 34.267,487.452 40.905,455.165 41.049,455.165
		47.98,487.452 54.763,487.452 64.119,447.927 57.639,447.927 51.522,480.243 51.369,480.243 44.812,447.927 37.517,447.927
		30.801,480.243 30.653,480.243 24.311,447.927 17.826,447.927 	" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="74.842,487.452 82.065,487.452 88.699,455.165 88.857,455.165
		95.788,487.452 102.551,487.452 111.927,447.927 105.437,447.927 99.321,480.243 99.163,480.243 92.596,447.927 85.305,447.927
		78.6,480.243 78.451,480.243 72.109,447.927 65.62,447.927 	" />
        <polygon clipPath="url(#SVGID_2_)" fill="#FFFFFF" points="122.63,487.452 129.858,487.452 136.492,455.165 136.641,455.165
		143.582,487.452 150.354,487.452 159.72,447.927 153.24,447.927 147.114,480.243 146.966,480.243 140.399,447.927 133.104,447.927
		126.393,480.243 126.245,480.243 119.903,447.927 113.403,447.927 	" />
        <rect x="163.21" y="479.648" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.778" height="7.804" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M185.326,467.704
		c0-9.012,1.107-15.492,7.972-15.492c5.751,0,7.223,6.059,7.223,14.744c0,10.181-1.472,16.221-7.223,16.221
		C186.659,483.177,185.326,475.363,185.326,467.704 M179.502,500.432h6.188v-17.466h0.153c1.404,3.384,4.726,5.521,8.259,5.521
		c10.248,0,12.908-12.539,12.908-21.531c0-11.715-3.533-20.055-12.908-20.055c-4.281,0-7.736,2.962-8.551,5.905h-0.148v-4.88h-5.901
		V500.432z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M219.042,463.563v-1.477
		c0-2.339,0.729-10.314,7.219-10.314c5.455,0,6.864,5.225,6.864,9.653v2.138H219.042z M239.605,468.433v-2.502
		c0-8.484-1.112-19.029-13.201-19.029c-12.155,0-14.083,11.427-14.083,21.32c0,13.564,4.055,20.266,13.718,20.266
		c10.766,0,13.268-9.807,13.268-14.006h-6.183c0,2.943-0.825,9.146-6.721,9.146c-7.583,0-7.583-9.146-7.583-15.194H239.605z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M258.634,452.212
		c6.634,0,7.956,7.813,7.956,15.492c0,8.091-1.322,14.446-8.033,14.446c-6.174,0-7.151-8.043-7.151-14.446
		C251.406,458.271,252.882,452.212,258.634,452.212 M272.419,447.927h-5.896v5.033h-0.163c-0.806-3.106-4.267-6.059-8.552-6.059
		c-10.018,0-12.884,10.104-12.884,20.803c0,5.234,0.729,19.748,12.232,19.748c3.911,0,7.593-1.994,8.925-5.302h0.153v5.167
		c0,2.723,0.288,8.839-7.679,8.839c-3.24,0-6.059-1.399-6.337-5.004h-6.192c1.016,9.874,10.535,9.874,12.951,9.874
		c7.976,0,13.44-4.275,13.44-16.076V447.927z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M280.922,487.452h6.184v-39.516
		h-6.184V487.452z M280.922,441.897h6.184v-7.095h-6.184V441.897z" />
        <rect x="296.97" y="479.648" clipPath="url(#SVGID_2_)" fill="#FFFFFF" width="6.787" height="7.804" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M313.641,487.452h6.202v-39.516
		h-6.202V487.452z M313.641,441.897h6.202v-7.095h-6.202V441.897z" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M328.328,487.452h6.174v-27.273c0-5.752,4.506-7.967,7.822-7.967
		c5.599,0,5.829,4.946,5.829,7.967v27.273h6.192v-28.021c0-4.63,0-12.529-10.411-12.529c-3.815,0-7.947,2.07-9.567,5.694h-0.153
		v-4.669h-5.886V487.452z" />
        <path clipPath="url(#SVGID_2_)" fill="#FFFFFF" d="M364.306,487.452h6.222v-34.646h6.758v-4.88h-6.758v-4.63
		c0-2.732,1.304-3.758,3.969-3.758h2.866v-5.312h-4.496c-5.982,0-8.561,3.097-8.561,9.069v4.63h-5.743v4.88h5.743V487.452z" />
        <path clipPath="url(#SVGID_2_)" fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M379.634,467.704
		c0,11.12,2.818,20.783,14.236,20.783c11.36,0,14.227-9.663,14.227-20.783c0-11.149-2.8-20.803-14.227-20.803
		C382.53,446.901,379.634,456.555,379.634,467.704 M393.727,483.637c-6.347,0-7.583-7.401-7.583-15.933
		c0-8.57,1.332-15.933,7.583-15.933c6.634,0,7.899,7.362,7.899,15.933C401.626,476.235,400.293,483.637,393.727,483.637" />
      </g>
    </svg>
  );
};
