import * as React from "react";
import { Phase } from "../registry";
import classNames = require("classnames");
import { Tooltip } from "react-daisyui";

export function EpisodePhase({ phase }: { phase?: Phase }) {
  const phaseData = (() => {
    switch (phase) {
      case "wr":
        return { label: "Writing", index: 1 };
      case "te":
        return { label: "Testing and Editing", index: 2 };
      case "tr":
        return { label: "Translating", index: 3 };
      case "rc":
        return { label: "Recording & Sound Design", index: 4 };
      case "ft":
        return { label: "Final testing", index: 5 };
      case "ac":
        return { label: "Ready for release", index: 6 };
      case "av":
        return { label: "Available on the stores", index: 7 };
      default:
        return { label: "Ready for Writing", index: 0 };
    }
  })();

  return (
    <Tooltip message={phaseData.label}>
      <div className="grid grid-cols-2 gap-0.5 place-content-center h-full">
        {[1, 2, 3, 4, 5, 6, 7].map((i) => (
          <span
            key={i}
            className={classNames("border rounded-full", {
              "bg-gray-800 border-gray-700": phaseData.index >= i,
              "border-gray-800": phaseData.index < i,
              "w-1.5 h-1.5": i < 7,
              "w-3.5 h-1.5 col-span-2": i === 7,
            })}
          />
        ))}
      </div>
    </Tooltip>
  );
}
