import * as React from "react";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  HandRaisedIcon,
} from "@heroicons/react/24/outline";
import { LogAction } from "./LogAction";

export interface Log {
  created: number;
  levelname: "DEBUG" | "INFO" | "WARNING" | "ERROR";
  message: string;
}

const logColor = (levelname: Log["levelname"], weight: "200" | "500") => {
  switch (levelname) {
    case "INFO":
      return weight === "200" ? "text-indigo-200" : "text-indigo-500";
    case "WARNING":
      return weight === "200" ? "text-amber-200" : "text-amber-500";
    case "ERROR":
      return "text-red-500"; // errors are important, let's show them
    case "DEBUG":
      return weight === "200" ? "text-blue-200" : "text-blue-500";
    default:
      return weight === "200" ? "text-pink-200" : "text-pink-500";
  }
};

const logIcon = (levelname: Log["levelname"]): React.ReactElement => {
  switch (levelname) {
    case "INFO":
      return <InformationCircleIcon className="w-4 h-4" />;
    case "WARNING":
      return <ExclamationTriangleIcon className="w-4 h-4" />;
    case "ERROR":
      return <ExclamationCircleIcon className="w-4 h-4" />;
    case "DEBUG":
      return <HandRaisedIcon className="w-4 h-4" />;
    default:
      return <QuestionMarkCircleIcon className="w-4 h-4" />;
  }
};

export function LogLine({ log, duration }: { log: Log; duration: string }) {
  return (
    <div
      className={
        "flex flex-row flex-wrap sm:flex-nowrap gap-x-2 mb-2 sm:mb-0 text-sm " +
        logColor(log.levelname, "200")
      }
    >
      <span className={"grow-0 font-mono"}>
        {new Date(log.created * 1000).toLocaleTimeString()}
      </span>
      <span className={"grow-0 font-mono"}>[{duration}s]</span>
      <span className={"grow-0 font-mono " + logColor(log.levelname, "500")}>
        {logIcon(log.levelname)}
      </span>
      <span className="grow break-all">
        {log.levelname !== "DEBUG" && (
          <span className="font-mono">{log.message}</span>
        )}
        {log.levelname == "DEBUG" && (
          <LogAction action={JSON.parse(log.message)} />
        )}
      </span>
    </div>
  );
}
