import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useSWR from "swr";

export function useStateLS<T>(
  name: string,
  initialState: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(initialState);

  const setValueLS = (newValue: T) => {
    setValue(newValue);
    localStorage.setItem(name, JSON.stringify(newValue));
  };

  useEffect(() => {
    const prevValueStr = window.localStorage.getItem(name);
    if (prevValueStr) {
      setValue(JSON.parse(prevValueStr));
    }
  }, []);

  return [value, setValueLS];
}

interface MySelf {
  isStaff: boolean;
  isSuperuser: boolean;
  canUseExperimentalFeatures: boolean;
}

export function useUser() {
  const fetcher = (url: string) => fetch(url).then((res) => res.json());
  return useSWR<MySelf>("/api/myself", fetcher, {
    fallbackData: { isStaff: false, isSuperuser: false, canUseExperimentalFeatures: false },
  });
}

type Message = {
  action: "go-to-line";
  line: number;
};

export function sendMessage(target: Window, message: Message) {
  const uuid = crypto.randomUUID();
  let count = 0;

  const send = () => {
    count += 1;
    target.postMessage({ uid: uuid, ...message }, "*");
  };

  let interval: NodeJS.Timer | null = null;
  setTimeout(() => {
    interval = setInterval(() => {
      send();
      if (count >= 10) {
        clearInterval(interval);
      }
    }, 500);
  }, 500);

  window.addEventListener("message", (event) => {
    if (
      event.data.action === "handled" &&
      event.data.uid === uuid &&
      interval != null
    ) {
      clearInterval(interval);
    }
  });

  send();
}

export function durationAsHumanReadable(duration: number): string {
  const seconds = duration % 60,
    minutes = Math.floor(duration / 60);

  return `${minutes}m ${seconds}s`;
}

export function nonEmpty(obj: any): any {
  Object.keys(obj).forEach(key => {
    if (obj[key] as unknown === undefined) {
      delete obj[key];
    }
  });
  return obj;
}
