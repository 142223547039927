import * as React from "react";
import {
  BookAnalysis,
  BookAnalysisMessageLevel,
  PathWithDuration,
} from "../defs";
import {
  ExclamationCircleIcon as ExclamationCircleIconSolid,
  ExclamationTriangleIcon as ExclamationTriangleIconSolid,
  InformationCircleIcon as InformationCircleIconSolid,
  ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid,
  ClipboardDocumentListIcon as ClipboardDocumentListIconSolid,
  ArrowPathIcon,
} from "@heroicons/react/24/solid";
import {
  ExclamationCircleIcon as ExclamationCircleIconOutline,
  ExclamationTriangleIcon as ExclamationTriangleIconOutline,
  InformationCircleIcon as InformationCircleIconOutline,
  ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconOutline,
  ClipboardDocumentListIcon as ClipboardDocumentListIconOutline,
} from "@heroicons/react/24/outline";
import { AnalysisFilters } from "./defs";
import { durationAsHumanReadable } from "../../utils";
import { useState } from "react";
import { Button, Modal, Tooltip } from "react-daisyui";

function CheckableIndicator({
  level,
  count,
  selected,
  toggleFiltering,
}: {
  level: BookAnalysisMessageLevel;
  count: number;
  selected: boolean;
  toggleFiltering: (level: BookAnalysisMessageLevel) => void;
}) {
  const iconClassname = "flex-none h-8 w-8 font-bold";
  let Icon, bgColor;
  switch (level) {
    case "ok":
      Icon = selected
        ? InformationCircleIconSolid
        : InformationCircleIconOutline;
      bgColor = "bg-blue-700";
      break;
    case "warning":
      Icon = selected
        ? ExclamationTriangleIconSolid
        : ExclamationTriangleIconOutline;
      bgColor = "bg-yellow-400";
      break;
    case "error":
      Icon = selected
        ? ExclamationCircleIconSolid
        : ExclamationCircleIconOutline;
      bgColor = "bg-red-600";
      break;
    case "grammar":
      Icon = selected
        ? ChatBubbleBottomCenterTextIconSolid
        : ChatBubbleBottomCenterTextIconOutline;
      bgColor = "bg-amber-600";
      break;
    case "similarity":
      Icon = selected
        ? ClipboardDocumentListIconSolid
        : ClipboardDocumentListIconOutline;
      bgColor = "bg-fuchsia-800";
      break;
  }

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => toggleFiltering(level)}
    >
      <Tooltip message={level} position="bottom" className="capitalize">
        <Icon className={iconClassname} />
        <span
          className={`h-6 w-6 flex justify-center items-center absolute right-[-0.5rem] top-[-0.5rem] text-sm text-center rounded-full ${bgColor}`}
        >
          {count}
        </span>
      </Tooltip>
    </div>
  );
}

function PathMetadata({
  label,
  path,
}: {
  label: string;
  path: PathWithDuration;
}) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="p-4 sm:p-6 flex flex-col gap-1">
      <h4 className="font-bold">{label} duration:</h4>
      <span>Reading: {durationAsHumanReadable(path.duration.reading)}</span>
      <span>Listening: {durationAsHumanReadable(path.duration.listening)}</span>

      <div>
        <Button color="neutral" size="xs" onClick={() => setOpenModal(true)}>
          See chapters in path
        </Button>
      </div>
      <Modal backdrop open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Chapters in {label}</Modal.Header>
        <Modal.Body>
          <div className="space-y-2">
            {path.chapters.map((chapter) => (
              <p
                className="text-base leading-relaxed text-gray-400"
                key={chapter}
              >
                {chapter}
              </p>
            ))}
          </div>
        </Modal.Body>
        <Modal.Actions>
          <Button color="neutral" onClick={() => setOpenModal(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export function MageBookAnalysisMetadata({
  analysis,
  filters,
  onReload,
  onNewFilters,
}: {
  analysis: BookAnalysis;
  filters: AnalysisFilters;
  onReload: () => void;
  onNewFilters: (newFilters: AnalysisFilters) => void;
}) {
  const toggleFiltering = (key: keyof AnalysisFilters): void => {
    // if all keys in filters are true, set all to false and key to true
    if (Object.values(filters).every((v) => v)) {
      onNewFilters({
        ok: key === "ok",
        warning: key === "warning",
        error: key === "error",
        grammar: key === "grammar",
        similarity: key === "similarity",
      });
      return;
    }

    const newFilters = { ...filters, [key]: !filters[key] };

    // if all keys in newFilters are false, set all to true
    if (Object.values(newFilters).every((v) => !v)) {
      onNewFilters({
        ok: true,
        warning: true,
        error: true,
        grammar: true,
        similarity: true,
      });
    } else {
      onNewFilters(newFilters);
    }
  };

  return (
    <div className="flex flex-col divide-y divide-gray-700">
      <div className="flex-grow flex flex-row gap-2 items-baseline p-4 sm:p-6 self-end">
        <CheckableIndicator
          level="ok"
          count={analysis.messagesCounts.ok}
          selected={filters.ok}
          toggleFiltering={toggleFiltering}
        />
        <CheckableIndicator
          level="warning"
          count={analysis.messagesCounts.warning}
          selected={filters.warning}
          toggleFiltering={toggleFiltering}
        />
        <CheckableIndicator
          level="grammar"
          count={analysis.messagesCounts.grammar}
          selected={filters.grammar}
          toggleFiltering={toggleFiltering}
        />
        <CheckableIndicator
          level="similarity"
          count={analysis.messagesCounts.similarity}
          selected={filters.similarity}
          toggleFiltering={toggleFiltering}
        />
        <CheckableIndicator
          level="error"
          count={analysis.messagesCounts.error}
          selected={filters.error}
          toggleFiltering={toggleFiltering}
        />
        <button
          type="button"
          onClick={onReload}
          className="ml-5 rounded-full text-gray-100 shadow-sm"
        >
          <ArrowPathIcon className="flex-none h-8 w-8 font-bold" />
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 divide-y sm:divide-y-0 sm:divide-x divide-gray-700">
        <PathMetadata label="Shortest path" path={analysis.shortestPath} />
        <PathMetadata label="Longest path" path={analysis.longestPath} />
      </div>
    </div>
  );
}
