import * as React from "react";

import { Book, PageData } from "./interfaces";
import { useEffect } from "react";

export interface HistoryPageStep {
  type: "page";
  chapterId: string;
  position: number;
}

export interface HistoryInfoStep {
  type: "info";
  subType: "choice" | "roll" | "checkpoint" | "error";
  text: string;
}

export type HistoryStep = HistoryPageStep | HistoryInfoStep;

interface Props {
  step: HistoryStep;
  book: Book;
  applyEffect: (effect: PageData["effect"]) => void;
}

export function AudioBookStep({ step, book, applyEffect }: Props) {
  if (step.type === "info") {
    const color =
      step.subType === "error"
        ? "text-red-500"
        : step.subType === "checkpoint"
          ? "text-indigo-500"
          : "text-yellow-500";
    return <p className={`italic font-light ${color}`}>{step.text}</p>;
  }

  const chapter = book.chapters[step.chapterId];
  const page = chapter.pages[step.position];

  if (step.position < chapter.pages.length) {
    if (page.effect) {
      useEffect(() => {
        const timer = setTimeout(() => {
          applyEffect(page.effect);
        }, 50);
        return () => clearTimeout(timer);
      }, []);
    }
    return (
      <p
        className={
          "flex flex-row gap-2 " +
          (page.effect ? "italic text-indigo-500 font-light" : "")
        }
      >
        {page.imageFile && (
          <>
            <img
              src="https://placehold.co/300x200"
              title={page.imageFile}
              alt="Image placeholder"
            />
            <br />
          </>
        )}
        {page.actor && (
          <span className="text-emerald-500 font-light">{page.actor}:</span>
        )}
        <span
          className={page.actor ? "text-emerald-200" : ""}
          dangerouslySetInnerHTML={{ __html: page.text }}
        ></span>
      </p>
    );
  }

  return null;
}
