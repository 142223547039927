import * as React from "react";
import { ConfirmationButton } from "../../../ds/ConfirmationButton";
import * as Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Button, Modal } from "react-daisyui";

interface Candidate {
  audio_file_path: string;
  audio_file: string;
  recognized_text: string;
  similarity: number;
}

export interface MissingAudioAction {
  type: "missing_audio";
  intended_text: string;
  audio_file: string;
  candidates: Candidate[];
}

export function MissingAudioModal({
  openModal,
  closeModal,
  action,
}: {
  openModal: boolean;
  closeModal: (completed: boolean) => void;
  action: MissingAudioAction;
}) {
  const { chapterId } = useParams();
  const [error, setError] = useState("");

  const overrideAudioId = (candidate: Candidate) => {
    const csrftoken = Cookies.get("csrftoken");
    fetch("/api/books/audio-id-override/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-csrftoken": csrftoken,
      },
      body: JSON.stringify({
        episode: chapterId,
        filename: action.audio_file,
        actual_filename: candidate.audio_file,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw Error(`${response.statusText}: ${await response.text()}`);
        }
      })
      .then(() => {
        setError("");
        closeModal(true);
      })
      .catch((err: Error) => setError(err.message));
  };

  return (
    <Modal
      backdrop
      open={openModal}
      onClose={() => closeModal(false)}
      className="text-neutral-content"
    >
      <Modal.Header>Missing audio</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed">
            An audio file could not be found. It could be the original text was
            slightly changed, and the ID changed as a result. We scanned the
            audio files and found some candidates that may match the expected
            text, please review them and select the best match. If none can be
            found close the modal and provide the audio file later.
          </p>

          <p className="text-md leading-relaxed">
            <strong>Expected text:</strong>{" "}
            <span className="underline">{action.intended_text}</span>
          </p>

          {action.candidates.map((candidate) => (
            <p
              key={candidate.audio_file_path}
              className="text-xs leading-relaxed"
            >
              <audio controls className="w-full">
                <source
                  src={
                    `/api/books/play-book-media-file/?path=` +
                    encodeURIComponent(candidate.audio_file_path)
                  }
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
              <strong>Recognized text:</strong> {candidate.recognized_text}
              <br />
              <strong>Similarity:</strong> {candidate.similarity} <br />
              <strong>File:</strong> {candidate.audio_file} <br />
              <ConfirmationButton
                color="neutral"
                onClick={() => overrideAudioId(candidate)}
                size="xs"
              >
                Set this as correct audio
              </ConfirmationButton>
            </p>
          ))}
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>
      </Modal.Body>
      <Modal.Actions>
        <Button color="neutral" onClick={() => closeModal(false)}>
          No candidates match, provide audio later
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
