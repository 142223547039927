import * as React from "react";
import { useState } from "react";
import { bookFetcher, makeBookUrl } from "../database";
import useSWR from "swr";
import { BookAnalysis } from "../defs";
import { useParams } from "react-router-dom";
import { MageBookAnalysis } from "./MageBookAnalysis";
import { MageBookAnalysisMetadata } from "./MageBookAnalysisMetadata";
import { AnalysisFilters } from "./defs";
import { useStateLS, useUser } from "../../utils";
import { CeleryTask } from "../tasks/CeleryTask";
import { RegistryEpisode } from "../../registry";
import { Loading } from "react-daisyui";
import EpisodeToolbar from "../books/EpisodeToolbar";

export function MageBookValidation() {
  const { chapterId } = useParams();
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [useExperimental] = useStateLS("useExperimental", false);
  const { data: user } = useUser();
  const bookUrl = makeBookUrl(
    parseInt(chapterId),
    user.isSuperuser && useExperimental,
  );
  const [analysis, setAnalysis] = useState<BookAnalysis | null>(null);

  const {
    data: chapter,
    isLoading: chapterIsLoading,
    error: chapterError,
  } = useSWR<RegistryEpisode>(`chapter-${chapterId}`, () =>
    fetch(`/api/books/chapters/${chapterId}/`).then((r) => r.json()),
  );

  const {
    data: taskRes,
    error,
    isLoading,
  } = useSWR<{ uid: string }, any, string>(
    `/api/books/chapters/${chapterId}/analyze/?t=${updatedAt.getTime()}`,
    (url) => bookFetcher(url),
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const onReload = () => {
    setUpdatedAt(new Date());
    setAnalysis(null);
  };

  const [analysisFilters, setAnalysisFilters] = useStateLS<AnalysisFilters>(
    "analysisFilters",
    {
      ok: true,
      warning: true,
      error: true,
      grammar: true,
      similarity: true,
    },
  );

  if (!taskRes) {
    return (
      <div className="border rounded border-gray-700 bg-gray-900 p-5 mt-5">
        {!error && (
          <div className="flex justify-center">
            <Loading size="lg" />
          </div>
        )}
        {error && (
          <div className="flex flex-col gap-2">
            <p>Error! It was not possible to validate this chapter.</p>
            {error.info && <pre>{error.info}</pre>}
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="w-full md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h3 className="text-lg leading-8 text-gray-100 sm:truncate sm:tracking-tight">
            Book validation: {chapter?.seriesTitle} - {chapter?.label}
          </h3>
        </div>
        <EpisodeToolbar chapter={chapter} useExperimental={false} compact />
      </div>

      {analysis && (
        <div className="border rounded border-gray-700 bg-gray-900 mt-5">
          <MageBookAnalysisMetadata
            analysis={analysis}
            onReload={onReload}
            filters={analysisFilters}
            onNewFilters={setAnalysisFilters}
          />
        </div>
      )}

      <div className="border rounded border-gray-700 bg-gray-900 px-4 sm:px-6 mt-5 py-5">
        {isLoading && (
          <div className="flex justify-center">
            <Loading size="lg" />
          </div>
        )}
        {!isLoading && error && (
          <div>
            Error {error.message}: {error.status}
            <pre className="overflow-x-auto">{error.info}</pre>
          </div>
        )}
        {!isLoading && !error && !analysis && (
          <CeleryTask
            taskId={taskRes.uid}
            onResult={(result) => setAnalysis(result as BookAnalysis)}
            renderResult={() => <span>Done! Loading validation</span>}
            refreshInterval={200}
          />
        )}
        {analysis && (
          <MageBookAnalysis
            analysis={analysis}
            filters={analysisFilters}
            bookUrl={bookUrl}
          />
        )}
      </div>
    </div>
  );
}
