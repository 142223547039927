import { BookAnalysisMessage } from "../defs";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import {
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { sendMessage } from "../../utils";
import { Button } from "react-daisyui";

export function MageBookAnalysisEntry(props: {
  message: BookAnalysisMessage;
  bookUrl: string;
}) {
  let iconClassname = "flex-none h-6 w-6 font-bold";
  let Icon, borderColor, textColor: string;

  switch (props.message.level) {
    case "ok":
      Icon = InformationCircleIcon;
      iconClassname += " text-blue-700";
      borderColor = "border-blue-700/50";
      textColor = "text-blue-50";
      break;
    case "warning":
      Icon = ExclamationTriangleIcon;
      iconClassname += " text-yellow-400";
      borderColor = "border-yellow-400/50";
      textColor = "text-yellow-50";
      break;
    case "error":
      Icon = ExclamationCircleIcon;
      iconClassname += " text-red-600";
      borderColor = "border-red-600/50";
      textColor = "text-red-50";
      break;
    case "grammar":
      Icon = ChatBubbleBottomCenterTextIcon;
      iconClassname += " text-amber-600";
      borderColor = "border-amber-600/50";
      textColor = "text-amber-50";
      break;
    case "similarity":
      Icon = ClipboardDocumentListIcon;
      iconClassname += " text-fuchsia-800";
      borderColor = "border-fuchsia-800/50";
      textColor = "text-fuchsia-50";
      break;
  }

  const openOnMagebook = (line: number | undefined) => {
    const newWindow = window.open(props.bookUrl, "Magenook-validation");
    sendMessage(newWindow, { action: "go-to-line", line: line });
  };

  return (
    <li className={`flex justify-between gap-3 flex-col ${textColor}`}>
      <div className="flex gap-2 items-center">
        <Icon className={iconClassname} />
        <ReactMarkdown
          components={{
            code({ node, inline, className, ...props }) {
              return (
                <code
                  {...props}
                  className={`underline underline-offset-2 ${textColor} text-opacity-70`}
                />
              );
            },
          }}
          className="flex-auto"
        >
          {props.message.message}
        </ReactMarkdown>
      </div>
      {props.message.info && (
        <pre
          className={`overflow-x-auto text-sm border-l-2 rounded-md p-2 ${borderColor}`}
        >
          {props.message.info}
        </pre>
      )}
      {props.message.line && (
        <div>
          <Button
            color="neutral"
            size="xs"
            onClick={() => openOnMagebook(props.message.line)}
          >
            Open in editor
          </Button>
        </div>
      )}
    </li>
  );
}
