import * as React from "react";
import { useUser } from "../../utils";
import useSWR from "swr";
import { availableTasks, RegistryEpisode, TaskName } from "../../registry";
import { useParams, useSearchParams } from "react-router-dom";
import * as Cookies from "js-cookie";
import { CeleryTask } from "./CeleryTask";
import { useState } from "react";
import { Button, Modal } from "react-daisyui";
import EpisodeToolbar from "../books/EpisodeToolbar";

interface Task {
  uid: string;
}

const fetchStartTask = async (taskName: TaskName, chapterId: string) => {
  const csrftoken = Cookies.get("csrftoken");
  const res = await fetch("/api/books/tasks/", {
    method: "POST",
    body: new URLSearchParams({ taskName, chapterId }),
    headers: { "x-csrftoken": csrftoken, accept: "application/json" },
  });

  if (res.ok) {
    return res.json();
  }
  throw {
    message: "An error occurred while fetching the data.",
    info: await res.text(),
    status: res.status,
  };
};

export function BookTasks() {
  const { data: user } = useUser();
  const { chapterId } = useParams();
  const [taskName, setTaskName] = React.useState<TaskName | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<TaskName | null>(null);

  const {
    data: chapter,
    isLoading: chapterIsLoading,
    error: chapterError,
  } = useSWR<RegistryEpisode>(`chapter-${chapterId}`, () =>
    fetch(`/api/books/chapters/${chapterId}/`).then((r) => r.json()),
  );

  const {
    data: task,
    isLoading: taskIsLoading,
    error: taskError,
    mutate: mutateTask,
  } = useSWR<Task>(
    taskName && ["task", taskName],
    () => fetchStartTask(taskName, chapterId),
    {
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (data) => {
        setSearchParams({ taskId: data.uid });
      },
    },
  );

  if (!user.isSuperuser) {
    return <div>Access denied</div>;
  }

  if (chapterIsLoading) {
    return <div>Loading...</div>;
  }

  if (chapterError) {
    return <div>Error fetching book info</div>;
  }

  const runTask = async (newTaskName: TaskName) => {
    setShowModal(false);
    if (taskName === newTaskName) {
      return mutateTask();
    }
    setTaskName(newTaskName);
  };

  const askConfirmation = async (newTaskName: TaskName) => {
    setShowModal(true);
    setSelectedTask(newTaskName);
  }

  return (
    <div>
      {selectedTask && (
        <Modal
          backdrop
          open={showModal}
          onClose={() => setShowModal(false)}
          className="text-neutral-content">
          <Modal.Header>Are you sure?</Modal.Header>
          <Modal.Body>You are running
            the <span className="underline">{availableTasks.find((t) => t.name === selectedTask).label}</span> task, do you want to
            proceed?</Modal.Body>
          <Modal.Actions>
            <Button color="primary" onClick={() => runTask(selectedTask)}>Confirm</Button>
            <Button color="neutral" onClick={() => setShowModal(false)}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      )}

      <div className="w-full md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h3 className="font-bold text-lg leading-8 text-gray-100 sm:truncate sm:tracking-tight">
            Tasks for {chapter.seriesTitle} - {chapter.label}
          </h3>
        </div>
        <EpisodeToolbar chapter={chapter} useExperimental={false} compact />
      </div>

      <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
        {availableTasks.map((task) => (
          <div
            key={task.name}
            className={
              "rounded-md bg-base-200 text-neutral-content border-neutral-content p-5 flex flex-col text-center items-center gap-2 " +
              (task.enabled ? "cursor-pointer hover:bg-base-300" : "opacity-40")
            }
            onClick={() => task.enabled && askConfirmation(task.name)}
          >
            <task.icon className="h-10 w-10" />
            {task.label}
          </div>
        ))}
      </div>
      {(taskName || searchParams.has("taskId")) && (
        <div className="border rounded border-gray-700 bg-gray-900 p-5 mt-5">
          {taskName && taskIsLoading && <div>Preparing {taskName}...</div>}
          {taskName && taskError && (
            <div>Could not start task: {taskError.info}</div>
          )}
          {searchParams.has("taskId") && (
            <CeleryTask
              key={searchParams.get("taskId")}
              taskId={searchParams.get("taskId")}
            />
          )}
        </div>
      )}
    </div>
  );
}
