import * as React from "react";
import { ConfirmationButton } from "../../../ds/ConfirmationButton";
import * as Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Button, Modal } from "react-daisyui";

export interface SpeechToTextAction {
  type: "speech_to_text";
  intended_text: string;
  recognized_text: string;
  audio_file_path: string;
  audio_file: string;
  audio_md5: string;
}

export function SpeechToTextModal({
  openModal,
  closeModal,
  action,
}: {
  openModal: boolean;
  closeModal: (completed: boolean) => void;
  action: SpeechToTextAction;
}) {
  const { chapterId } = useParams();
  const [error, setError] = useState("");

  const overrideAudioText = () => {
    const csrftoken = Cookies.get("csrftoken");
    fetch("/api/books/audio-text-content-override/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-csrftoken": csrftoken,
      },
      body: JSON.stringify({
        filename: action.audio_file,
        md5: action.audio_md5,
        text: action.intended_text,
        episode: chapterId,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw Error(`${response.statusText}: ${await response.text()}`);
        }
      })
      .then(() => {
        setError("");
        closeModal(true);
      })
      .catch((err: Error) => setError(err.message));
  };

  return (
    <Modal
      backdrop
      open={openModal}
      onClose={() => closeModal(false)}
      className="text-neutral-content"
    >
      <Modal.Header>Possibly invalid audio content</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <p className="text-base leading-relaxed">
            An audio file has been detected with potentially wrong text. Please
            review its content and verify the text shown below is correct. If
            not, please replace the audio file and run extraction again.
          </p>

          <p className="text-md leading-relaxed">
            <strong>Expected text:</strong>{" "}
            <span className="underline">{action.intended_text}</span>
          </p>

          <audio controls className="w-full">
            <source
              src={
                `/api/books/play-book-media-file/?path=` +
                encodeURIComponent(action.audio_file_path)
              }
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          <p className="text-xs leading-relaxed">
            <strong>Recognized text:</strong> {action.recognized_text} <br />
          </p>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>
      </Modal.Body>
      <Modal.Actions>
        <ConfirmationButton onClick={overrideAudioText} color="primary">
          The content is correct
        </ConfirmationButton>
        <Button color="neutral" onClick={() => closeModal(false)}>
          The content is incorrect, replace audio
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
