import * as React from "react";

import {
  Route,
  Link,
  RouterProvider,
  createRoutesFromElements,
  createHashRouter,
  Outlet
} from "react-router-dom";
import { AudioBook } from "./audiobook/AudioBook";
import { MageBookValidation } from "./validation/MageBookValidation";
import { PlayNookLogo } from "./PlayNookLogo";
import { UserDropdown } from "./UserDropdown";
import { BookTasks } from "./tasks/BookTasks";
import { MageBookListUI } from "./books/MageBookListUI";

const Layout = () => {
  return (
    <div className="mx-auto max-w-6xl px-6 pb-24 pt-10 sm:pb-40 lg:flex lg:px-8">
      <div className="mx-auto w-full flex-shrink-0 lg:mx-0 lg:pt-8 text-gray-50">
        <div className="flex flex-row justify-between">
          {/* [sp] if we use <Link> here we break search filters when navigating */}
          <a className="flex gap-2" href="/">
            <PlayNookLogo />
            <h2 className="text-2xl font-bold leading-7 text-gray-100 sm:truncate sm:text-3xl sm:tracking-tight">
              PlayNook HQ
            </h2>
          </a>
          <UserDropdown />
        </div>
        <Outlet />
      </div>
    </div>);
};

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<MageBookListUI />} />
      <Route path="/play/:chapterId" element={<AudioBook />} />
      <Route
        path="/validate/:chapterId"
        element={<MageBookValidation />}
      />
      <Route path="/tasks/:chapterId" element={<BookTasks />} />
    </Route>
  )
);


export class App extends React.Component<{}, {}> {
  public render(): React.ReactElement {
    return (
      <div className="bg-gray-900 min-h-screen">
        <main>
          {/* Hero section */}
          <div className="relative isolate overflow-hidden min-h-screen">
            <svg
              className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                  width={200}
                  height={200}
                  x="50%"
                  y={-1}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                <path
                  d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
              />
            </svg>
            <div
              className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
              aria-hidden="true"
            >
              <div
                className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
                style={{
                  clipPath:
                    "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
                }}
              />
            </div>
            <RouterProvider router={router} />
          </div>
        </main>
      </div>
    );
  }
}
