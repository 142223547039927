import * as React from "react";
import Pegi3Icon from "./Pegi3Icon";
import Pegi7Icon from "./Pegi7Icon";
import Pegi12Icon from "./Pegi12Icon";
import Pegi16Icon from "./Pegi16Icon";
import Pegi18Icon from "./Pegi18Icon";

export type PegiValue = "p3" | "p7" | "p12" | "p16" | "p18"

export default function PegiIcon({ pegi }: { pegi: PegiValue }) {
  return (
    <div className="w-8">
      {(pegi === "p3") ? <Pegi3Icon /> : (pegi === "p7") ? <Pegi7Icon /> : (pegi === "p12") ?
        <Pegi12Icon /> : (pegi === "p16") ? <Pegi16Icon /> : <Pegi18Icon />}
    </div>
  );
}
